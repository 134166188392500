import { IntercomProvider } from 'react-use-intercom';
import { $path } from 'remix-routes';

import { useUserAnalytics } from '../../analytics/user';
import config from '../../config';
import { useInstance } from '../../hooks/useInstance';
import { LoginStateSyncer } from '../../pages/CrossStorage/LoginStateSyncer';
import { GamePackContextProvider } from '../../pages/GamePack/Context';
import { GamePackCRUDUtility } from '../../pages/GamePack/CRUDUtility';
import { RequireActivation } from '../Access';
import { GlobalBlockedAccess } from '../Access/GlobalBlocked';
import { UserAccess } from '../Access/UserAccess';
import { type BreadcrumbNode } from '../Breadcrumbs';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../ConfirmCancelModalContext';
import { GameCenterContextProvider, HomeBreadcrumb } from '../Game/GameCenter';
import { GameCoverClip } from '../Game/GamePackCoverPres';
import { NotificationCenter } from '../Notification';
import {
  NotificationContextProvider,
  useNotificationDataSourceReactState,
} from '../Notification/Context';
import { ProvidersList } from '../ProvidersList';
import { UserContextProvider } from '../UserContext';
import { VenueContextProvider } from '../Venue';

export function PublicHomeProvider(props: { children?: React.ReactNode }) {
  const breadcrumb = useInstance<BreadcrumbNode>(() => ({
    key: 'GamePacks',
    node: <HomeBreadcrumb label={'Home'} to={$path('/home')} />,
  }));

  const notificationDataSource = useNotificationDataSourceReactState();

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <IntercomProvider appId={config.misc.intercomAppId} />,
    <LoginStateSyncer
      enabled={config.crossStorage.enabled}
      origin={config.crossStorage.origin}
    />,
    <GlobalBlockedAccess />,
    <RequireActivation />,
    <UserAccess />,
    <VenueContextProvider />,
    <ConfirmCancelModalProvider />,
    <NotificationContextProvider datasource={notificationDataSource} />,
  ];

  return (
    <ProvidersList providers={providers}>
      <GameCenterContextProvider>
        <GamePackContextProvider
          embed={false}
          mode='public-home'
          pageType='public'
          routePrefix={$path('/home')}
          editRoutePrefix=''
          breadcrumb={breadcrumb}
        >
          {props.children}

          <GamePackCRUDUtility />
        </GamePackContextProvider>
      </GameCenterContextProvider>

      <NotificationCenter
        className='!w-108'
        position='bottom-right'
        toastClassName='rounded my-2'
      />
      <ConfirmCancelModalRoot />
      <GameCoverClip id='game-cover-clip' />
    </ProvidersList>
  );
}
